<template>
<div>
    <div class="py-3"></div>
    <div class="barra"></div>

    <div class="row">
        <div class="col-md-6">
            <div class="alert alert-success" v-if="info">
                {{ info }}
            </div>
            <div v-if="validationErrors">
                <ul class="alert alert-warning">
                    <li v-for="(value, index) in validationErrors" :key="index">{{ value[0] }}</li>
                </ul>
            </div>
            <div class="form-group">
              <ul class="alert alert-warning" v-if="submited">
                  <li v-if="submited && !$v.email.email">Correo electrónico no valido.</li>
                  <li v-if="submited && !$v.email.required">Se requiero un correo electrónico.</li>
                  <li v-if="submited && !$v.subject.required">Se requiero un asunto.</li>
                  <li v-if="submited && !$v.subject.minLength">El asunto debe tener al menos 5 caracteres.</li>
                  <li v-if="submited && !$v.subject.maxLength">El asunto debe tener al máximo 20 caracteres.</li>
                  <li v-if="submited && !$v.content.required">Se requiero un contenido.</li>
                  <li v-if="submited && !$v.content.minLength">El contenido debe tener al menos 5 caracteres.</li>
                  <li v-if="submited && !$v.content.maxLength">El contenido debe tener al menos 200 caracteres.</li>
              </ul>
            </div>
        </div>
    </div>

    <!-- Contact -->
    <div class="contact container_custom">
        <div class="container">
            <div class="row">

                <!-- Contact Content -->
                <div class="col-xl-6 text-center">
                    <div class="contact_form_container">
                        <div class="section_title">
                            <h1>Contactanos</h1>
                        </div>
                        <form class="contact_form" id="contact_form">
                            <div>
                                <input name="email" autofocus type="text" class="contact_input" placeholder="Email" v-model="email">
                            </div>
                            <div>
                                <input name="subject" type="text" class="contact_input" placeholder="Asunto" v-model="subject">
                            </div>
                            <div>
                                <textarea name="content" class="contact_input contact_textarea" placeholder="Mensaje" v-model="content"></textarea>
                            </div>
                            <div>
                                <button type="button" class="contact_button trans_200" @click="sendContact">Enviar mensaje</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import axios from 'axios';

import {
    mapState
} from 'vuex'

export default {
    name: "vContact",

    data() {
        return {
            email: '',
            subject: '',
            content: '',
            info: '',
            validationErrors: '',
            submited:false
        }
    },
    validations: {
        email: {
            required,
            email,
            maxLength: maxLength(32)
        },
        subject: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(20)
        },
        content: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(200)
        },
    },
    methods: {
        sendContact(e){
            this.submited = true;
            e.preventDefault();
            if(this.$v.$invalid){
                return false;
            }
            const url = this.appDomainApi1 + "contact-student";
            const params = {
                email: this.email,
                subject: this.subject,
                content: this.content
            };                
            axios
                .post(url, params)
                .then(res => {
                    this.info = 'Mensaje enviardo, Gracias por comunicarte con nosotros.';
                    if (res.status == 200){
                        this.email=''
                        this.subject=''
                        this.content=''
                        this.validationErrors= ''
                        this.submited=false
                    } 
                })
                .catch(err => {
                    console.error(err); 
                    if (err.response.status == 422){
                        this.validationErrors = err.response.data.errors;
                    } 
                })
                this.info= ''
        }
    },
    computed: {
        ...mapState([
            'student',
        ])
    },
}
</script>

<style>
.barra {
    height: 100px;
}

/*********************************
6. Contact
*********************************/

.contact {
    background: #FFFFFF;
    border-bottom: solid 2px #e1ecf4;
}

.contact_form {
    display: block;
    margin-top: 39px;
}

.contact_form>div {
    margin-bottom: 21px;
}

.contact_input {
    width: 100%;
    height: 56px;
    background: #e1ecf4;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 28px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.contact_input::-webkit-input-placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #9d9d9d !important;
}

.contact_input:-moz-placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #9d9d9d !important;
}

.contact_input::-moz-placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #9d9d9d !important;
}

.contact_input:-ms-input-placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #9d9d9d !important;
}

.contact_input::input-placeholder {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #9d9d9d !important;
}

.contact_textarea {
    height: 195px;
    padding-top: 20px;
}

.contact_button {
    width: 187px;
    height: 56px;
    background: #0077FF;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    margin-top: 13px;
}

.contact_button:hover {
    box-shadow: 0px 23px 43px rgba(0, 0, 0, 0.25);
}

/** Responsive **/

/************
1. 1620px
************/

@media only screen and (max-width: 1620px) {}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px) {}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px) {}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px) {
    .main_nav ul li:not(:last-of-type) {
        margin-right: 25px;
    }

    .add_listing {
        margin-left: 30px;
    }

    .log_reg {
        margin-left: 20px;
    }
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px) {
    .container_custom .container {
        max-width: 960px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .contact_content {
        padding-left: 0;
    }

    .contact_map_container {
        height: 650px;
    }

    .contact_form_col {
        margin-bottom: 21px;
    }

    .footer_container {
        padding-left: 0;
        padding-right: 0;
    }
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px) {}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px) {}

/************
6. 991px
************/

@media only screen and (max-width: 991px) {
    .container_custom .container {
        max-width: 720px;
    }

    .main_nav {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .search_input {
        width: calc(100% - 170px);
    }

    .search_button {
        width: 152px;
    }

    .footer_nav ul li:not(:last-of-type) {
        margin-right: 31px;
    }
}

/************
7. 959px
************/

@media only screen and (max-width: 959px) {}

/************
8. 880px
************/

@media only screen and (max-width: 880px) {}

/************
9. 768px
************/

@media only screen and (max-width: 768px) {}

/************
10. 767px
************/

@media only screen and (max-width: 767px) {
    .container_custom .container {
        max-width: 540px;
    }

    .add_listing {
        width: 122px;
        height: 38px;
    }

    .add_listing {
        display: none;
    }

    .log_reg ul li a {
        font-size: 14px;
    }

    .menu_link {
        display: block;
    }

    .blog_post {
        width: 100%;
    }

    .footer_container {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .copyright {
        margin-top: 20px;
    }
}

/************
11. 575px
************/

@media only screen and (max-width: 575px) {
    .container_custom .container {
        max-width: 100%;
    }

    .button {
        width: 156px;
        height: 47px;
    }

    .button a {
        font-size: 14px;
        line-height: 47px;
    }

    .header_content {
        height: 90px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .logo a {
        font-size: 20px;
    }

    .search_button {
        margin-top: 15px;
    }

    .search_input {
        padding-left: 0;
        width: 100%;
        text-align: center;
    }

    .home {
        height: 350px;
    }

    .home_content {
        padding-bottom: 85px;
    }

    .home_title {
        font-size: 36px;
    }
}

/************
11. 539px
************/

@media only screen and (max-width: 539px) {}

/************
12. 480px
************/

@media only screen and (max-width: 480px) {}

/************
13. 479px
************/

@media only screen and (max-width: 479px) {}

/************
14. 400px
************/

@media only screen and (max-width: 400px) {}
</style>
